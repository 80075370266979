import { Component, OnDestroy, OnInit } from '@angular/core';

import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Observable, Subject } from 'rxjs';
import { switchMap, first, tap, finalize, takeUntil } from 'rxjs/operators';

import { AccountService } from '../../services/account.service';
import { ModalService } from '../../services/modal.service';

import { Account } from '../../models/account';
import { SiteService } from '../../services/site.service';
import { UniqueId } from '../../uniqueId';
import _ from 'lodash';

@Component({
  selector: 'app-account-arrangement-locality-list',
  templateUrl: './account-arrangement-locality-list.component.html',
  styleUrls: ['./account-arrangement-locality-list.component.scss']
})
export class AccountArrangementLocalityListComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  account$: Observable<Account>;

  constructor(
    private siteService: SiteService,
    private modalService: ModalService,
    private accountService: AccountService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.account$ = this.accountService.account$;

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
      
  }

  private findLocalityGroups(localityId: any, localityGroups: any): string[] {
    const allLocalityGroups = [];
    if (!localityGroups) {
      return [];
    }

    for (const group  of localityGroups) {
      const isFound = group.value?.find((locality:any) => {return localityId === locality.value });
      if (isFound) {
        allLocalityGroups.push(group);
      }
    }
    return allLocalityGroups;
  }

  onEditLocality(event: Event, locality: any, localityGroups: any): void {
    
    const groupsForLocality = this.findLocalityGroups(locality._meta.id, localityGroups);

    const localityValue = {
      name: locality.name,
      value: locality.value,
      group: groupsForLocality,
      newGroup: '',
    }

    const modal = this.modalService.addAccountLocality({
      title: 'Add Locality',
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Save', key: 'save', class: '' },
      ],
      data: localityValue,
    });

    modal.onClose.subscribe({
      next: (reason: string) => {
        if (reason && reason.toString().toLowerCase() === 'save') {
          this.editAccountData(localityValue, locality);
        }
      }
    });

  }

  onDeleteLocality(event: Event, contact: any): void {
    
    const modal = this.modalService.generic({
      title: 'Remove Item',
      copy: ['Are you sure you want to remove this locality?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-danger', icon: 'pi pi-trash' },
      ]
    });

    modal.onClose.subscribe({
      next: (reason: string) => {

        if (reason.toString().toLowerCase() === 'confirm') {
        
          contact.disabled = true;

          this.updateAccountData();

        }

      }
    });

  }

  onRestoreLocality(event: Event, contact: any): void {
    
    const modal = this.modalService.generic({
      title: 'Restore Item',
      copy: ['Are you sure you want to restore this locality?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-success', icon: 'pi pi-refresh' },
      ]
    });

    modal.onClose.subscribe({
      next: (reason: string) => {

        if (reason.toString().toLowerCase() === 'confirm') {
        
          contact.disabled = false;

          this.updateAccountData();

        }

      }
    });

  }

  private updateAccountData(): void {

    this.siteService.addSubscriptionLog(this, 'account-arrangement-locality-list.component.ts->updateAccountData->this.accountService.account$');

    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-arrangement-locality-list.component.ts->updateAccountData->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(),
      switchMap(account => this.accountService.patch(account.data)),
    ).subscribe({
      next: res => {
        // console.log(res.data);
      }
    });

  }

  private editAccountData(localityValue: any, locality: any): void {

    this.siteService.addSubscriptionLog(this, 'account-arrangement-locality-list.component.ts->editAccountData->this.accountService.account$');

    this.accountService.account$.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('account-arrangement-locality-list.component.ts->updateAccountData->this.accountService.account$')),
      takeUntil(this.unsubscribe$),
      first(account => account !== undefined)
    ).subscribe({
      next: (account) => {
        const uniqueId = new UniqueId();

        // Update name of locality
        locality.name = localityValue.name;
        locality.value = localityValue.value;

        // Check if there is update in localityGroup for a specific locality then update account.data.localityGroup entries
        const localityGroupsForLocality: any[] = this.findLocalityGroups(locality._meta.id, account.data.localityGroup);
        if (!_.isEqual(localityValue.group, localityGroupsForLocality)) {
          // for each localityGroup that exists on accountData
          account.data.localityGroup.forEach((localityGroup: any, localityGroupIndex: number) => {

            // If account locality group has been included in editing
            const localityGroupIncluded = localityValue.group.find((element: any) => {return element._meta.id === localityGroup._meta.id});
            
            if (localityGroupIncluded) {

              const checkLocalityExists = localityGroup.value?.find((item: any)=> {return item.value === locality._meta.id});
              // If edited locality doesn't exist inside the current account localityGroup , then add new entry
              if (!checkLocalityExists) {
                
                if (!localityGroup.value) {
                  localityGroup.value = [];
                }

                const id = uniqueId.getUniqueInArray(localityGroup.value, '_meta.id', 'id-');
                const index = localityGroup.value.length;

                account.data.localityGroup[localityGroupIndex].value.push({
                  _meta: { id, index },
                  value: locality._meta.id,
                }); 
              }
            } // else: current locality group was removed from edited entry so need to update the localityGroup localities list
            else {
              const localityexists = localityGroup.value?.findIndex((element: any) => {return element.value === locality._meta.id});
              if (localityexists >= 0) {
                
                if (localityGroup.value.length > 1) {
                  account.data.localityGroup[localityGroupIndex].value.splice(localityexists, 1);
                } else {
                  account.data.localityGroup[localityGroupIndex].value = null;
                }
                
              }
            }

            
          });
          
        }

        // If new group has been added
        if (localityValue.newGroup) {

          if (!account.data.localityGroup) {
            account.data.localityGroup = [];
          }

          const group_id = uniqueId.getUniqueInArray(account.data.localityGroup, '_meta.id', 'id-');
          const group_index = account.data.localityGroup.length;

          const new_s_id = uniqueId.getUniqueInArray([], '_meta.id', 'id-');
          const new_s_index = 0;

          account.data.localityGroup.push({
            _meta: { id: group_id, index: group_index },
            name: localityValue.newGroup,
            value: [
              {
                _meta: { id: new_s_id, index: new_s_index },
                value: locality._meta.id,
              }
            ],
          });
        }
        
        this.accountService.patch(account.data).subscribe({
          next: (res) => {
            //console.log(res.data);
          }
        });
      }
    });
  }
}
