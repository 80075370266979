import { environment as base } from './environment.base';

base.production = true;

base.status.awayTimeout = 300000; // 300,000ms === 5min

base.webAppUrl = 'https://fdconductor.com';
base.api.host = 'https://api.fdconductor.com';

base.appSync.graphql.host = '7evkavs6jnhpngjuomle2gmvse.appsync-api.ap-southeast-2.amazonaws.com';
base.appSync.graphql.http = 'https://7evkavs6jnhpngjuomle2gmvse.appsync-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.graphql.wss = 'wss://7evkavs6jnhpngjuomle2gmvse.appsync-realtime-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.apiKey = 'da2-wjiu6654zzfvpl5bnq65ufd2bq';
base.stripe.publicKey = 'pk_live_M0hWCkKUyHk8IvR0oajguTeV';

base.email.sendAsync = true;

export const environment = base;
