import { Directive, Input, QueryList, ViewChildren } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Parser } from "json2csv";
import { MenuItem } from "primeng/api";
import { FileUpload } from "primeng/fileupload";
import { Menu } from "primeng/menu";
import { Table } from "primeng/table";
import { TieredMenu } from "primeng/tieredmenu";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, finalize, first, tap } from "rxjs/operators";
import { changeDetection } from "../../change-detection";
import { Service } from "../../classes/service";
import { FormGenerator } from "../../forms/form-generator";
import { ModalService } from "../../services/modal.service";
import { stringToKey } from "../../string-to-key";
import { UniqueId } from "../../uniqueId";
import { DynamicFormStructure } from "../dynamic-form-structure";
import { DynamicModel } from "../dynamic-model";
import csvToJson from 'csvtojson';

import _ from "lodash";
import { FormStructure } from "../../forms/form-structure";
import { SiteService } from "../../services/site.service";

@Directive()
export class GenericServiceProvider {

  @ViewChildren('fileUploadElement') fileUploadElements!: QueryList<FileUpload>;
  @ViewChildren('table') tableElements!: QueryList<Table>;

  @Input() data!: DynamicModel;

  fileUpload!: FileUpload;
  table!: Table;

  csvExportUrl!: SafeResourceUrl;

  additionalOperations: MenuItem[];
  modifyCategories: MenuItem[];
  
  service!: Service;
  
  formReady: BehaviorSubject<boolean>;
  
  products: BehaviorSubject<any[]>;
  categories: BehaviorSubject<any[]>;
  
  clonedProducts: { [s: string]: any; } = {};
  clonedCategories: { [s: string]: any; } = {};

  activeProductIndex?: number;
  activeCategoryIndex?: number;

  subscriptions: Subscription[];

  constructor(
    private siteService: SiteService,
    private modalService: ModalService
  ) {

    this.formReady = new BehaviorSubject<boolean>(false);
    this.products = new BehaviorSubject<any[]>([]);
    this.categories = new BehaviorSubject<any[]>([]);

    this.additionalOperations = [
      { label: 'Description', icon: 'pi pi-fw pi-plus', command: () => this.onEditDescription() },
      { label: 'Notes', icon: 'pi pi-fw pi-plus', command: () => this.onEditNotes()  },
      { label: 'Invoice Label', icon: 'pi pi-fw pi-plus', command: () => this.onEditInvoiceLabel()  },
    ];

    this.modifyCategories = [
      { label: 'New', icon: 'pi pi-fw pi-plus', command: () => this.onEditCategory(null) },
      { label: 'Edit', icon: 'pi pi-fw pi-pencil', items: [] },
      { label: 'Delete', icon: 'pi pi-fw pi-trash', styleClass: 'menu-item-delete', items: [] },
      { label: 'Restore', icon: 'pi pi-fw pi-refresh', styleClass: 'menu-item-restore', items: [] },
    ];

    this.subscriptions = [];

    this.siteService.addSubscriptionLog(this, 'generic-service-provider.ts->constructor->this.subscriptions.push(this.categories');

    this.subscriptions.push(this.categories.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('generic-service-provider.ts->constructor->this.subscriptions.push(this.categories')),
      debounceTime(100)
    ).subscribe({
      next: (categories: any[]) => {

        let editMenuItems: MenuItem[] = [];
        let deleteMenuItems: MenuItem[] = [];
        let restoreMenuItems: MenuItem[] = [];

        _.map(categories, (category: any, index: number) => {

          editMenuItems.push({
            label: category.name,
            command: () => this.onEditCategory(index)
          });

          if (category.disabled) {

            restoreMenuItems.push({
              label: category.name,
              command: () => this.onRestoreCategory(index)
            });

          } else {

            deleteMenuItems.push({
              label: category.name,
              command: () => this.onDeleteCategory(index)
            });

          }

        });

        this.modifyCategories[1].items = editMenuItems;

        const deleteIndex = _.findIndex(this.modifyCategories, mc => mc.label?.toLowerCase() === 'delete');
        
        if (deleteMenuItems.length) {
          this.modifyCategories[deleteIndex].visible = true;
          this.modifyCategories[deleteIndex].items = deleteMenuItems;
        } else {
          this.modifyCategories[deleteIndex].visible = false;
        }
        
        const restoreIndex = _.findIndex(this.modifyCategories, mc => mc.label?.toLowerCase() === 'restore');

        if (restoreMenuItems.length) {
          this.modifyCategories[restoreIndex].visible = true;
          this.modifyCategories[restoreIndex].items = restoreMenuItems;
        } else {
          this.modifyCategories[restoreIndex].visible = false;
        }

      }
    }));

  }

  ngOnInit(formStructure: FormStructure): void {

    this.service = this.data.service;

    this.generateForm(formStructure);

  }

  ngAfterViewInit(): void {
    
    this.siteService.addSubscriptionLog(this, 'generic-service-provider.ts->ngAfterViewInit->this.fileUploadElements.changes');

    this.fileUploadElements.changes.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('generic-service-provider.ts->ngAfterViewInit->this.fileUploadElements.changes')),
      first((queryList: QueryList<FileUpload>) => queryList.length > 0)
    ).subscribe({
      next: (queryList: QueryList<FileUpload>) => {
        this.fileUpload = queryList.first;
      }
    });
    
    this.siteService.addSubscriptionLog(this, 'generic-service-provider.ts->ngAfterViewInit->this.tableElements.changes');

    this.tableElements.changes.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('generic-service-provider.ts->ngAfterViewInit->this.tableElements.changes')),
      first((queryList: QueryList<Table>) => queryList.length > 0)
    ).subscribe({
      next: (queryList: QueryList<Table>) => {
        this.table = queryList.first;
      }
    });

    changeDetection(() => {
      this.formReady.next(true);
    });

  }

  ngOnDestroy(): void {
    
  }

  onExportCSV(event: Event) {

    const products = this.service.form.get('products')?.value;

    const data = _.map(products, product => {
      return {
        id: product._meta.id,
        name: product.name,
        category: product.category.name,
        cost: product.cost.amount,
        bespoke: ((product.cost.bespoke) ? 1 : 0),
        description: product.description,
        note: product.note,
      };
    });

    const fileName = stringToKey(this.service.name) + '.csv';

    const json2csvParser = new Parser();

    const csv = json2csvParser.parse(data);

    let file = new File([csv], fileName, { type: 'text/csv' });

    let exportUrl = URL.createObjectURL(file);

    window.location.assign(exportUrl);

    // URL.revokeObjectURL(exportUrl);

  }

  onAddCategory(event: Event | null, index?: number): void {

    const formGroup = this.generateProductCategoryFormGroup();
    
    const categoriesFormArray = this.service.form.get('productCategories') as FormArray;
    
    if (categoriesFormArray) {

      if (index !== undefined) {

        categoriesFormArray.insert(index, formGroup);

      } else{

        categoriesFormArray.push(formGroup);

      }

      this.categories.next(this.formatProductCategoriesForSelectMenu(categoriesFormArray.value));

    }

  }

  onAddProduct(event: Event | null, index?: number): void {

    const uniqueId = new UniqueId();
    const formGenerator = new FormGenerator();
    const dynamicFormStructure = new DynamicFormStructure();

    const id = uniqueId.getUniqueInArray(this.products.value, '_meta.id', 'id-');
    const metaIndex = this.products.value.length;

    const formStructure = dynamicFormStructure._getGenericProductStructure();

    const formGroup = formGenerator.generate(formStructure, {
      _meta: { id, index: metaIndex },
      name: 'N/A',
      category: this.categories.value[0].value,
      cost: { amount: 0 }
    });

    const productsFormArray = this.service.form.get('products') as FormArray;

    if (productsFormArray) {

      if (index !== undefined) {

        productsFormArray.insert(index, formGroup);

        this.products.next(productsFormArray.value);

      } else{

        // productsFormArray.push(formGroup);

      }

    }

  }

  onDeleteProduct(event: Event, product: any, index: number) {

    const modalRef = this.modalService.generic({
      title: 'Remove Item',
      copy: ['Are you sure you want to remove this item?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: '' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-danger', icon: 'pi pi-trash' },
      ]
    });

    modalRef.onClose.subscribe({
      next: (reason: string) => {
        if (reason.toString().toLowerCase() === 'confirm') {
        
          const productsFormArray = this.service.form.get('products') as FormArray;

          if (productsFormArray) {

            productsFormArray.removeAt(index);

            this.products.next(productsFormArray.value);

          }

        }
      }
    });

  }

  onRowEditInit(product: any) {
    this.clonedProducts[product._meta.id] = _.cloneDeep(product);
  }

  onRowEditSave(product: any, index: number) {
    this.updateModifiedProduct(product, index);
  }
  
  onRowEditCancel(product: any, index: number) {
    this.revertModifiedProduct(product, index);
  }

  onDisplayAdditionalOperationsMenu(event: Event, menu: Menu, index: number) {

    this.activeProductIndex = index;

    menu.toggle(event);

  }

  onDisplayModifyCategoriesMenu(event: Event, menu: TieredMenu, index: number) {

    menu.toggle(event);

  }

  onEditDescription(): void {
    
    const products = this.products.value;

    if (products && products.length && this.activeProductIndex !== undefined) {

      const product = products[this.activeProductIndex];
      const input = { input: product.description };
      const activeProductIndex = this.activeProductIndex;
      
      this.clonedProducts[product._meta.id] = _.cloneDeep(product);

      const modalRef = this.modalService.inputTextArea({
        title: 'Product Description',
        buttons: [
          { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
          { label: ((product.description) ? 'Update' : 'Save'), key: 'save', class: '' },
        ],
        data: input,
      });
  
      modalRef.onClose.subscribe({
        next: (reason: string) => {

          if (reason && reason.toString().toLowerCase() === 'save') {
            product.description = input.input;
            this.updateModifiedProduct(product, activeProductIndex);
          } else {
            this.revertModifiedProduct(product, activeProductIndex);
          }
        }
      });

    }

  }

  onEditNotes(): void {
    
    const products = this.products.value;

    if (products && products.length && this.activeProductIndex !== undefined) {

      const product = products[this.activeProductIndex];
      const input = { input: product.note };
      const activeProductIndex = this.activeProductIndex;

      this.clonedProducts[product._meta.id] = _.cloneDeep(product);

      const modalRef = this.modalService.inputTextArea({
        title: 'Product Note',
        buttons: [
          { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
          { label: ((product.note) ? 'Update' : 'Save'), key: 'save', class: '' },
        ],
        data: input,
      });
  
      modalRef.onClose.subscribe({
        next: (reason: string) => {

          if (reason && reason.toString().toLowerCase() === 'save') {
            product.note = input.input;
            this.updateModifiedProduct(product, activeProductIndex);
          } else {
            this.revertModifiedProduct(product, activeProductIndex);
          }
        }
      });

    }

  }


  onEditInvoiceLabel(): void {
    
    const products = this.products.value;

    if (products && products.length && this.activeProductIndex !== undefined) {

      const product = products[this.activeProductIndex];
      const input = { input: product.invoiceLabel };
      const activeProductIndex = this.activeProductIndex;

      this.clonedProducts[product._meta.id] = _.cloneDeep(product);

      const modalRef = this.modalService.inputTextInput({
        title: 'Invoice Label',
        buttons: [
          { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
          { label: ((product.invoiceLabel) ? 'Update' : 'Save'), key: 'save', class: '' },
        ],
        data: input,
      });
  
      modalRef.onClose.subscribe({
        next: (reason: string) => {
          if (reason && reason.toString().toLowerCase() === 'save') {
            product.invoiceLabel = input.input;
            this.updateModifiedProduct(product, activeProductIndex);
          } else {
            this.revertModifiedProduct(product, activeProductIndex);
          }
        }
      });

    }

  }

  onEditCategory(index: number | null): void {
    
    const categories: FormArray = this.service.form.get('productCategories') as FormArray;

    if (categories && categories.length) {

      let categoryFormGroup: FormGroup;
      let categoryValue: any;

      if (index === null) {

        categoryFormGroup = this.generateProductCategoryFormGroup();
        categoryValue = categoryFormGroup.value;

      } else {

        categoryFormGroup = categories.at(index) as FormGroup;
        categoryValue = categoryFormGroup.value;
        
        this.clonedCategories[categoryValue._meta.id] = _.cloneDeep(categoryValue);

      }

      const modalRef = this.modalService.inputNameValuePair({
        title: 'Product Category',
        buttons: [
          { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
          { label: ((index !== null) ? 'Update' : 'Save'), key: 'save', class: '' },
        ],
        data: categoryValue,
      });
  
      modalRef.onClose.subscribe({
        next: (reason: string) => {
          if (reason && reason.toString().toLowerCase() === 'save') {
            this.updateModifiedCategory(categoryValue, index);
          } else {
            if (index !== null) {
              this.revertModifiedCategory(categoryValue, index);
            }
          }
        }
      });

    }

  }

  onDeleteCategory(index: number): void {

    const categories: FormArray = this.service.form.get('productCategories') as FormArray;

    const category = categories.at(index).value;

    const modalRef = this.modalService.generic({
      title: 'Delete Category',
      copy: [`Are you sure you want to delete the '${ category?.name }' category?`],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Delete', key: 'delete', class: '' },
      ],
    });

    modalRef.onClose.subscribe({
      next: (reason: string) => {
        if (reason.toLowerCase() === 'delete') {
          category.disabled = true;
          this.updateModifiedCategory(category, index);
        }
      }
    });

  }

  onRestoreCategory(index: number): void {

    const categories: FormArray = this.service.form.get('productCategories') as FormArray;

    const category = categories.at(index).value;

    const modalRef = this.modalService.generic({
      title: 'Restore Category',
      copy: [`Are you sure you want to restore the '${ category?.name }' category?`],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Restore', key: 'restore', class: '' },
      ],
    });

    modalRef.onClose.subscribe({
      next: (reason: string) => {
        if (reason.toLowerCase() === 'restore') {
          category.disabled = false;
          this.updateModifiedCategory(category, index);
        }
      }
    });

  }

  onBespokeProductPricingToggle(event: Event, product: any, index: number): void {

    product.cost.bespoke = !product.cost.bespoke;

    const productsValue: any[] = this.products.value;

    productsValue[index] = product;

    this.products.next(productsValue);

    const formArray = this.service.form.get('products') as FormArray;

    formArray.at(index).patchValue(product);

  }

  upload(event: any) {

    let input = event.files;

    let reader: FileReader = new FileReader();

    reader.readAsText(input[0]);

    reader.onload = (e) => {

      const csv = reader.result as string;

      this.parseCSV(csv);

      event = null;

    }

  }

  private generateForm(formStructure: FormStructure) {

    const formGenerator = new FormGenerator();

    const formData = this.service.form.value;
    // We don't want to include the productCategories and products in the form data because we generate it separately
    // in the `generateCategories` and `generateProducts` methods
    _.unset(formData, 'productCategories');
    _.unset(formData, 'products');
    formGenerator.generate(formStructure, formData, this.service.form);

    this.generateCategories();

    this.generateProducts();

  }

  private revertModifiedProduct(product: any, index: number): void {

    const productsValue: any[] = this.products.value;

    productsValue[index] = this.clonedProducts[product._meta.id];

    this.products.next(productsValue);

    delete this.clonedProducts[product._meta.id];

  }

  private revertModifiedCategory(category: any, index: number): void {

    const categoriesValue: any[] = this.categories.value;

    categoriesValue[index] = this.clonedCategories[category._meta.id];

    this.categories.next(this.formatProductCategoriesForSelectMenu(categoriesValue));

    const formArray = this.service.form.get('productCategories') as FormArray;

    formArray.at(index).patchValue(this.clonedCategories[category._meta.id]);

    delete this.clonedCategories[category._meta.id];

  }

  private updateModifiedProduct(product: any, index: number): void {

    const productsValue: any[] = this.products.value;

    productsValue[index] = product;

    this.products.next(productsValue);

    const formArray = this.service.form.get('products') as FormArray;

    formArray.at(index).patchValue(product);

    delete this.clonedProducts[product._meta.id];

  }

  private updateModifiedCategory(category: any, index: number | null): void {

    const categoriesValue: any[] = this.categories.value;

    if (index === null) {

      categoriesValue.push(category);

    } else { 

      categoriesValue[index] = category;

    }
    
    const formArray = this.service.form.get('productCategories') as FormArray;

    if (index === null) {
      
      this.onAddCategory(null);

      formArray.at(formArray.length - 1).get('name')?.patchValue(category.name);
      formArray.at(formArray.length - 1).get('value')?.patchValue(category.value);
      
    } else {

      formArray.at(index).patchValue(category);

    }

    this.categories.next(this.formatProductCategoriesForSelectMenu(categoriesValue));

    delete this.clonedCategories[category._meta.id];

  }

  private generateCategories(): void {

    const productCategoriesFormArray = this.service.form.get('productCategories') as FormArray;

    // This should never happen, but in the off chance that is does we should deal with it
    if (!productCategoriesFormArray) {

      console.error(`We expected the 'productCategories' FormArray to exist, but it doesn't.`);

      return;

    }

    // If we DON'T have existing categories, create the default categories
    if (!productCategoriesFormArray.length) {

      this.onAddCategory(null);

      productCategoriesFormArray.at(0).patchValue({ name: 'Miscellaneous', value: 'miscellaneous' });

    }

    // Now that we have some categories, we need to add them to `this.categories`
    this.categories.next(this.formatProductCategoriesForSelectMenu(productCategoriesFormArray.value));

  }

  private generateProducts(): void {

    const productFormArray = this.service.form.get('products') as FormArray;

    // This should never happen, but in the off chance that is does we should deal with it
    if (!productFormArray) {

      console.error(`We expected the 'productFormArray' FormArray to exist, but it doesn't.`);

      return;

    }

    // If we have existing products, restore them
    if (productFormArray.length) {

      this.products.next(productFormArray.value);

    } else { // If we DON'T have existing products, create one blank product for the user to populate
      
      this.onAddProduct(null);

    }

  }

  private generateProductCategoryFormGroup(): FormGroup {

    const uniqueId = new UniqueId();
    const formGenerator = new FormGenerator();
    const dynamicFormStructure = new DynamicFormStructure();

    const productCategoriesForArray = this.service.form.get('productCategories') as FormArray;
    const productCategoriesValue = productCategoriesForArray.value;

    const id = uniqueId.getUniqueInArray(productCategoriesValue, '_meta.id', 'id-');
    const metaIndex = productCategoriesValue.length;

    const formStructure = dynamicFormStructure._getGenericProductCategoryStructure();

    const formGroup = formGenerator.generate(formStructure, {
      _meta: { id, index: metaIndex },
      name: null,
      value: null,
    });

    return formGroup;

  }

  private formatProductCategoriesForSelectMenu(productCategoriesValue: any[]): any[] {

    return _.map(productCategoriesValue, category => {

      if (typeof category.value === 'string') {

        return {
          disabled: category.disabled,
          name: category.name,
          value: { name: category.name, value: category.value },
        };

      } else {

        return category;

      }

    })

  }

  private async parseCSV(csv: string): Promise<void> {

    const rowToFormGroup = (csvRow: any, index: number) => {

      const productCategoriesFormArray = this.service.form.get('productCategories') as FormArray;
      const productsFormArray = this.service.form.get('products') as FormArray;

      // Search the existing product categories to see if the category in the csvRow exists
      const getExistingProductCategory = (rowCategory: string): FormGroup | null => {

        let foundCategory: FormGroup | null = null;
        const productCategoriesLength = productCategoriesFormArray.length;

        for (let i = 0; i < productCategoriesLength; i++) {

          const productCategoryFormGroup = productCategoriesFormArray.at(i) as FormGroup;
  
          const productCategoryValue = productCategoryFormGroup.value;
  
          const productCategoryKey = productCategoryValue.value;
          const rowCategoryAsKey = stringToKey(rowCategory);
  
          if (rowCategoryAsKey === productCategoryKey) {
  
            foundCategory = productCategoryFormGroup;
            
            break;
  
          }
  
        }

        return foundCategory;

      };

      // Search the existing products to see if the product ID in the csvRow exists
      const getExistingProduct = (rowId: string): FormGroup | null => {

        let foundProduct: FormGroup | null = null;
        
        const productsLength = productsFormArray.length;

        if (!rowId) {

          return foundProduct;

        }

        for (let i = 0; i < productsLength; i++) {

          const productFormGroup = productsFormArray.at(i) as FormGroup;
  
          const productValue = productFormGroup.value;
  
          const productId = productValue._meta.id;
  
          if (rowId.toLowerCase() === productId.toLowerCase()) {
  
            foundProduct = productFormGroup;
            
            break;
  
          }
  
        }

        return foundProduct;

      };

      // Create a new product category
      const createNewProductCategory = (category: string): void => {

        const newProductCategoryFormGroup = this.generateProductCategoryFormGroup();

        newProductCategoryFormGroup.get('name')?.patchValue(category);
        newProductCategoryFormGroup.get('value')?.patchValue(stringToKey(category));

        productCategoriesFormArray.push(newProductCategoryFormGroup);

        this.categories.value.push(newProductCategoryFormGroup.value);
        this.categories.next(this.formatProductCategoriesForSelectMenu(this.categories.value));

      };

      // Create a new product
      const createNewProduct = (product: any): void => {

        const uniqueId = new UniqueId();
        const formGenerator = new FormGenerator();
        const dynamicFormStructure = new DynamicFormStructure();
    
        const id = uniqueId.getUniqueInArray(this.products.value, '_meta.id', 'id-');
        const metaIndex = this.products.value.length;
    
        const formStructure = dynamicFormStructure._getGenericProductStructure();
    
        const categoryIndex = _.findIndex(this.categories.value, category => category.value.value === stringToKey(product.category));

        const formGroup = formGenerator.generate(formStructure, {
          _meta: { id, index: metaIndex },
          name: product.name,
          category: this.categories.value[categoryIndex].value,
          cost: { 
            amount: product.cost,
            bespoke: (product.bespoke === 1) ? true : false
          },
          description: product.description,
          note: product.note,
        });

        productsFormArray.push(formGroup);

        this.products.next(productsFormArray.value);

      };

      // Update an existing product
      const updateExistingProduct = (formGroup: FormGroup, product: any): void => {

        const categoryIndex = _.findIndex(this.categories.value, category => category.value.value === stringToKey(product.category));

        formGroup.patchValue(_.assign({}, formGroup.value, {
          name: product.name,
          category: this.categories.value[categoryIndex].value,
          cost: { 
            amount: product.cost,
            bespoke: (product.bespoke === 1) ? true : false
          },
          description: product.description,
          note: product.note,
        }));

        this.products.next(productsFormArray.value);

      };

      let productCategoryFormGroup = getExistingProductCategory(csvRow.category);
      let productFormGroup = getExistingProduct(csvRow.id);

      if (!productCategoryFormGroup) {

        createNewProductCategory(csvRow.category);

      }

      if (!productFormGroup) {

        createNewProduct(csvRow);
        
      } else {
        
        updateExistingProduct(productFormGroup, csvRow);

      }

    };

    const rows: any[] = await csvToJson({
      checkType: true
    }).fromString(csv);
    
    const rowLength = rows.length;

    for (let i = 0; i < rowLength; i++) {
      rowToFormGroup(rows[i], i);
    }

    if (this.fileUpload) {
      this.fileUpload.clear();
    }

  }

}
