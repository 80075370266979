// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  webAppUrl: 'http://localhost:4200',
  production: false,
  aws: {
    region: 'ap-southeast-2',
  },
  forms: {
    dynamicRequiredClass: 'dynamic-required',
  },
  dbDateFormat: 'YYYY-MM-DD HH:mm:ss',
  status: {
    interval: 120000, // 120,000ms === 120sec
    awayTimeout: 600000, // 600,000ms === 10min
  },
  email: {
    sendAsync: false,
  },
  bdm: {
    nsw: {
      api: {
        username: '',
        password: '',
      },
      serviceProviderId: 65,
      productCategory: 'death-certificate',
      productName: 'Death Certificate'
    },
    qld: {
      api: {
        username: '',
        password: '',
      },
      serviceProviderId: 452,
      productCategory: 'death-certificate',
      productName: 'Death Certificate'
    },
    vic: {
      api: {
        username: '',
        password: '',
      },
      serviceProviderId: 451,
      productCategory: 'death-certificate',
      productName: 'Death Certificate'
    },
  },
  api: {
    host: 'http://localhost:8088/dev',
    paths: {
      api: {
        auth: {
          login: '/api/auth/login',
          register: '/api/auth/register',
          forgottenPassword: '/api/auth/forgot-password',
          resetPassword: '/api/auth/reset-password',
          status: '/api/auth/status'
        },
        user: {
          isAuthenticated: '/api/user/is-authenticated',
          patchStatus: '/api/user/status',
          patchMessage: '/api/user/message',
          getMessages: '/api/user/messages',
          getUnreadMessageCount: '/api/user/messages/unread',
          getMessagesById: '/api/user/messages/:userId',
          patchMessageToRead: '/api/user/messages/:userId/set-to-read',
          getAll: '/api/user',
          getRegions: '/api/user/regions',
          getRoles:'/api/user/roles',
          updateUser: '/api/user/',
          profile: '/api/user/profile',
          uploadFile: '/api/upload/file',
          uploadAvatar: '/api/upload/avatar',
          changePassword: '/api/user/change-password',
          enable: '/api/user/enable/:userId',
          disable: '/api/user/disable/:userId',
        },
        message: {
          direct: {
            patchMessage: '/api/message/direct',
            patchMessageToRead: '/api/message/direct/set-to-read',
            getMessages: '/api/message/direct',
          },
          groups: {
            patchMessage: '/api/message/sendMessage',
            getAll: '/api/groups',
            create: '/api/groups/create',
            getMessageById: '/api/groups/getMessages/:groupId',
          }
        },
        account: {
          get: '/api/account',
          getAll: '/api/account/all',
          getById: '/api/account/:accountId',
          post: '/api/account',
          patch: '/api/account',
        },
        files: {
          post: '/api/files',
          rename: '/api/files/rename/:fileId',
          getPreviewLink: '/api/files/get-preview-link/:fileId',
          getDownloadLink: '/api/files/get-download-link/:fileId',
          generatePresignedUploadUrl: '/api/files/generate-pre-signed-upload-url',
          saveUploadedFiles: '/api/files/save-uploaded-files',
        },
        arrangement: {
          get: '/api/arrangement',
          getById: '/api/arrangement/:arrangementId',
          lockById: '/api/arrangement/lock/:arrangementId',
          unlockById: '/api/arrangement/unlock/:arrangementId',
          post: '/api/arrangement',
          patch: '/api/arrangement/:id',
          delete: '/api/arrangement/:id',
          addDoc: '/api/arrangement/add-document',
          updateDoc: '/api/arrangement/update-document',
          document: {
            create: '/api/arrangement/:arrangementId/documents',
            getAll: '/api/arrangement/:arrangementId/documents',
            deleteBatch: '/api/arrangement/:arrangementId/documents/batch',
          },
          contact: {
            post: '/api/arrangement/:id/contact',
            patch: '/api/arrangement/:id/contact',
          },
          bdm: {
            nsw: {
              lodge: '/api/arrangement/bdm/nsw/lodge'
            }
          },
          projectEstimate: {
            post: '/api/arrangement/:arrangementId/sync-project-tasks',
            reset: '/api/arrangement/:arrangementId/reset-project',
          },
          projectQuote: {
            post: '/api/arrangement/:arrangementId/generate-project-quote',
          },
          invoice: {
            post: '/api/arrangement/:arrangementId/invoice/',
          },
          simpleDataPdf: {
            post: '/api/arrangement/:arrangementId/generate-simple-data-pdf',
          },
          notifySendingInvoice: {
            post: '/api/arrangement/:arrangementId/notify-sending-invoice',
          },
        },
        serviceProvider: {
          get: '/api/service',
          getById: '/api/service/:id',
          post: '/api/service',
          patch: '/api/service/:id',
          delete: '/api/service/:id',
        },
        email: {
          send: '/api/email/send',
          get: '/api/email?toContactId=:toContactId&toServiceId=:toServiceId&arrangementId=:arrangementId',
          getById: '/api/email/:id?confirmationToken=:confirmationToken',
          read: '/api/email/:id/read?confirmationToken=:confirmationToken',
          accept: '/api/email/:id/accept?confirmationToken=:confirmationToken',
          reject: '/api/email/:id/reject?confirmationToken=:confirmationToken',
        },
        notification: {
          send: '/api/notification',
          read: '/api/notification/:id/read',
          archive: '/api/notification/:id/archive',
          getAll:'/api/notification',
          readAll:'/api/notification/read-all',
          archiveAll:'/api/notification/archive-all',
        },
        invoice: {
          get: '/api/invoices',
          getStats: '/api/invoices/stats',
          getById: '/api/invoices/:id',
          delete: '/api/invoices/:id',
          post: '/api/invoices',
          postXeroInvoice: '/api/invoices/:id/xero-invoices',
        },
        payment: {
          post: '/api/invoices/:id/payments',
          getByNth: '/api/invoices/:invoiceId/payments/:nthPayment',
          applyFee: '/api/invoices/:invoiceId/payments/:paymentId/apply-fee',
          confirm: '/api/invoices/:invoiceId/payments/:paymentId/confirm',
          reject: '/api/invoices/:invoiceId/payments/:paymentId/reject',
        }
      },
    },
  },
  appSync: {
    graphql: {
      host: '4bfnjgconvddrcgo5qq5wls4bu.appsync-api.ap-southeast-2.amazonaws.com',
      http: 'https://4bfnjgconvddrcgo5qq5wls4bu.appsync-api.ap-southeast-2.amazonaws.com/graphql',
      wss: 'wss://4bfnjgconvddrcgo5qq5wls4bu.appsync-realtime-api.ap-southeast-2.amazonaws.com/graphql',
    },
    apiKey: 'da2-5jzhledtkbfazp6dbkwf4ibzee',
  },
  stripe: {
    // Khanh's local key. Use your own or from environment.staging.ts
    publicKey: 'pk_test_qtmA9X5hxYVezMD8YXTAcinD003nbIN9aR'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
