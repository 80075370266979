<ng-container *ngIf="(account$ | async) as account">

  <p-card class="card-info w-full my-3">
    <p class="text-center"><strong>Note:</strong> For optimal performance, please add or edit localities within an <em>empty</em> arrangement. Making these changes in an active arrangement may cause issues.</p>
  </p-card>

  <p-table 
    #localitiesTable 
    [value]="account.data.localityGroup" 
    [globalFilterFields]="['name']"
    styleClass="mt-5 p-datatable-striped" 
    dataKey="_meta.id" 
    responsiveLayout="scroll"
  >

    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="localitiesTable.filterGlobal($any($event).target.value, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-localityGroup>
      <tr [pSelectableRow]="localityGroup">
        <td class="locality-text">{{ localityGroup.name }}</td>
        <td class="locality-actions">
          <button pButton pRipple type="button" icon="pi pi-pencil" (click)="onEditLocality($event, localityGroup)" class="p-button-rounded p-button-text"></button>
          <button *ngIf="!localityGroup.disabled" pButton pRipple type="button" icon="pi pi-trash" (click)="onDeleteLocality($event, localityGroup)" class="p-button-rounded p-button-danger p-button-text"></button>
        </td>
      </tr>
    </ng-template>

  </p-table>

</ng-container>